import React, { useState, useMemo } from 'react'
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    TableContainer,
    TableSortLabel,
    Toolbar,
    Autocomplete,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

import { styled } from '@mui/material/styles'

// -----------------------
//       HIGHCHARTS
// -----------------------
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

// -----------------------
//     EXPORT PACKAGES
// -----------------------
import { CSVLink } from 'react-csv'
import * as XLSX from 'xlsx'

// -----------------------
//   SORTING HELPERS
// -----------------------
function descendingComparator(a, b, orderBy) {
    // If your fields are numeric, we can compare them directly.
    // If they're strings, you may need to parse or adjust logic.
    if (b[orderBy] < a[orderBy]) return -1
    if (b[orderBy] > a[orderBy]) return 1
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        // If the order is the same, sort by the original index to maintain stability
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

// -----------------------
//  STYLED CELL EXAMPLE
// -----------------------
const HighlightedTableCell = styled(TableCell)(({ theme, value }) => {
    let background = 'inherit'
    if (value > 500000) {
        background = theme.palette.error.light // Red-ish if > 500k
    } else if (value > 300000) {
        background = theme.palette.warning.light // Yellow-ish if > 300k
    } else if (value > 100000) {
        background = theme.palette.success.light // Green-ish if > 100k
    }
    return {
        backgroundColor: background,
        transition: 'background-color 0.3s ease',
    }
})

// -----------------------
//       MOCK DATA
// -----------------------
const initialData = [
    {
        site: 'BW - BOIS COLOMBES',
        hidden: false,
        nombreChambre: 112,
        anneeLivraison: 2015,
        puissanceElecSouscrite: 120,
        puissanceElecAtteinte: 85,
        consoElecKwh: 330661,
        consoGazKwh: 215100,
        chauffageUrbainKwh: 0,
        totauxKwh: 545761,
        suSansLtEtParkingM2: 3471,
        nbreNuitee: 27631,
        TO: 72.1,
        consoKwhM2: 95,
        consoKwhChambre: 2952,
        consoKwhNuitee: 12,
        consoGazM3: 62,
        consoEauM3: 80,
        consoEauM3Nuitee: 2.44,
        consoEauLNuitee: 306.79,
        particularites: [],
        commentaires: '',
    },
    {
        site: 'LAZ',
        hidden: false,
        nombreChambre: 56,
        anneeLivraison: 2017,
        puissanceElecSouscrite: 110,
        puissanceElecAtteinte: 90,
        consoElecKwh: 294645,
        consoGazKwh: 429638,
        chauffageUrbainKwh: 0,
        totauxKwh: 724283,
        suSansLtEtParkingM2: 1830,
        nbreNuitee: 16623,
        TO: 81.3,
        consoKwhM2: 161,
        consoKwhChambre: 5262,
        consoKwhNuitee: 18,
        consoGazM3: 235,
        consoEauM3: 100,
        consoEauM3Nuitee: 2.68,
        consoEauLNuitee: 295.07,
        particularites: ['SPA'],
        commentaires: '',
    },
    {
        site: 'MERCURE',
        hidden: false,
        nombreChambre: 144,
        anneeLivraison: 2018,
        puissanceElecSouscrite: 129,
        puissanceElecAtteinte: 119,
        consoElecKwh: 363962,
        consoGazKwh: 256710,
        chauffageUrbainKwh: 0,
        totauxKwh: 620672,
        suSansLtEtParkingM2: 4605,
        nbreNuitee: 28862,
        TO: 54.9,
        consoKwhM2: 79,
        consoKwhChambre: 2528,
        consoKwhNuitee: 13,
        consoGazM3: 56,
        consoEauM3: 135,
        consoEauM3Nuitee: 1.53,
        consoEauLNuitee: 243.85,
        particularites: ['Restaurant'],
        commentaires: '',
    },
    {
        site: 'IBIS GENNEVILLIERS',
        hidden: false,
        nombreChambre: 133,
        anneeLivraison: 2018,
        puissanceElecSouscrite: 90,
        puissanceElecAtteinte: 65,
        consoElecKwh: 210948,
        consoGazKwh: 155152,
        chauffageUrbainKwh: 0,
        totauxKwh: 366100,
        suSansLtEtParkingM2: 2323,
        nbreNuitee: 31044,
        TO: 63.9,
        consoKwhM2: 91,
        consoKwhChambre: 1586,
        consoKwhNuitee: 7,
        consoGazM3: 67,
        consoEauM3: 60,
        consoEauM3Nuitee: 2.0,
        consoEauLNuitee: 150.01,
        particularites: ['Restaurant'],
        commentaires: '',
    },
    {
        site: 'COURTYARD',
        hidden: false,
        nombreChambre: 112,
        anneeLivraison: 2020,
        puissanceElecSouscrite: 120,
        puissanceElecAtteinte: 80,
        consoElecKwh: 367087,
        consoGazKwh: 25053,
        chauffageUrbainKwh: 177560,
        totauxKwh: 544647,
        suSansLtEtParkingM2: 3650,
        nbreNuitee: 20599,
        TO: 50.4,
        consoKwhM2: 101,
        consoKwhChambre: 3278,
        consoKwhNuitee: 18,
        consoGazM3: 49,
        consoEauM3: 149,
        consoEauM3Nuitee: 1.21,
        consoEauLNuitee: 215.25,
        particularites: [],
        commentaires: '',
    },
    {
        site: 'B&B',
        hidden: false,
        nombreChambre: 91,
        anneeLivraison: 2020,
        puissanceElecSouscrite: 110,
        puissanceElecAtteinte: 76,
        consoElecKwh: 186934,
        consoGazKwh: 0,
        chauffageUrbainKwh: 77090,
        totauxKwh: 264024,
        suSansLtEtParkingM2: 1876,
        nbreNuitee: 21277,
        TO: 64.1,
        consoKwhM2: 100,
        consoKwhChambre: 2054,
        consoKwhNuitee: 9,
        consoGazM3: 41,
        consoEauM3: 141,
        consoEauM3Nuitee: 1.96,
        consoEauLNuitee: 172.91,
        particularites: [],
        commentaires: '',
    },
    {
        site: 'IBIS ROMAINVILLE',
        hidden: false,
        nombreChambre: 120,
        anneeLivraison: 2020,
        puissanceElecSouscrite: 120,
        puissanceElecAtteinte: 100,
        consoElecKwh: 323084,
        consoGazKwh: 104648,
        chauffageUrbainKwh: 0,
        totauxKwh: 427732,
        suSansLtEtParkingM2: 3305,
        nbreNuitee: 25619,
        TO: 58.5,
        consoKwhM2: 98,
        consoKwhChambre: 2692,
        consoKwhNuitee: 13,
        consoGazM3: 32,
        consoEauM3: 129,
        consoEauM3Nuitee: 1.28,
        consoEauLNuitee: 165.62,
        particularites: [],
        commentaires: '',
    },
    {
        site: 'M13 - ASNIERES',
        hidden: false,
        nombreChambre: 117,
        anneeLivraison: 2022,
        puissanceElecSouscrite: 230, // 110 + 120
        puissanceElecAtteinte: 176, // 79 + 97
        consoElecKwh: 391191,
        consoGazKwh: 36005,
        chauffageUrbainKwh: 0,
        totauxKwh: 427196,
        suSansLtEtParkingM2: 3065,
        nbreNuitee: 25105,
        TO: 58.8,
        consoKwhM2: 128,
        consoKwhChambre: 3344,
        consoKwhNuitee: 16,
        consoGazM3: 12,
        consoEauM3: 139,
        consoEauM3Nuitee: 2.06,
        consoEauLNuitee: 251.34,
        particularites: [],
        commentaires: '',
    },
    {
        site: 'BELLUNE',
        hidden: false,
        nombreChambre: 105,
        anneeLivraison: 2022,
        puissanceElecSouscrite: 200,
        puissanceElecAtteinte: 161,
        consoElecKwh: 570186,
        consoGazKwh: 34920,
        chauffageUrbainKwh: 0,
        totauxKwh: 605106,
        suSansLtEtParkingM2: 3204,
        nbreNuitee: 26170,
        TO: 68.3,
        consoKwhM2: 178,
        consoKwhChambre: 5430,
        consoKwhNuitee: 22,
        consoGazM3: 11,
        consoEauM3: 189,
        consoEauM3Nuitee: 2.59,
        consoEauLNuitee: 316.89,
        particularites: ['SPA'],
        commentaires: '',
    },
    {
        site: 'TRIBE ST OUEN',
        hidden: false,
        nombreChambre: 112,
        anneeLivraison: 2023,
        puissanceElecSouscrite: 180,
        puissanceElecAtteinte: 75,
        consoElecKwh: 262635,
        consoGazKwh: 232814,
        chauffageUrbainKwh: 0,
        totauxKwh: 495449,
        suSansLtEtParkingM2: 3020,
        nbreNuitee: 17206,
        TO: 42.1,
        consoKwhM2: 87,
        consoKwhChambre: 2345,
        consoKwhNuitee: 15,
        consoGazM3: 77,
        consoEauM3: 164,
        consoEauM3Nuitee: 1.14,
        consoEauLNuitee: 199.81,
        particularites: [],
        commentaires: '',
    },
]

function EditDialog({ open, onClose, siteData, onSave }) {
    // We keep local state for the fields we want to edit
    const [nombreChambre, setNombreChambre] = useState(siteData?.nombreChambre || 0)
    const [commentaires, setCommentaires] = useState(siteData?.commentaires || '')
    const [particularites, setParticularites] = useState(siteData?.particularites || [])

    // Define all possible tags
    const ALL_TAGS = ['SPA', 'Restaurant', 'Piscine', 'Gym']

    // Handle check/uncheck a particularité
    const handleCheckChange = (tag) => {
        if (particularites.includes(tag)) {
            // remove tag
            setParticularites(particularites.filter((t) => t !== tag))
        } else {
            // add tag
            setParticularites([...particularites, tag])
        }
    }

    // On save, we call `onSave` with updated fields
    const handleSave = () => {
        onSave({
            ...siteData,
            nombreChambre,
            commentaires,
            particularites,
        })
    }

    if (!siteData) {
        // If no data is selected yet, do not render form
        return null
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Modifier le site: {siteData.site}</DialogTitle>
            <DialogContent dividers>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {/* NOMBRE DE CHAMBRE */}
                    <TextField
                        label="Nombre de chambre"
                        type="number"
                        value={nombreChambre}
                        onChange={(e) => setNombreChambre(e.target.value)}
                        fullWidth
                    />

                    {/* COMMENTAIRES */}
                    <TextField
                        label="Commentaires"
                        multiline
                        rows={3}
                        value={commentaires}
                        onChange={(e) => setCommentaires(e.target.value)}
                        fullWidth
                    />

                    {/* PARTICULARITÉS - Checkboxes */}
                    <Typography variant="subtitle1">Particularités:</Typography>
                    <Box>
                        {ALL_TAGS.map((tag) => (
                            <FormControlLabel
                                key={tag}
                                control={
                                    <Checkbox
                                        checked={particularites.includes(tag)}
                                        onChange={() => handleCheckChange(tag)}
                                    />
                                }
                                label={tag}
                            />
                        ))}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button variant="contained" onClick={handleSave}>
                    Sauvegarder
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const Home = () => {
    // -----------------------
    //   REACT HOOK STATES
    // -----------------------
    const [hotelsData, setHotelsData] = useState(initialData)

    // Filter states
    const [yearFilter, setYearFilter] = useState('')

    // Sorting states
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('site')
    const [siteFilter, setSiteFilter] = useState([]) // now an array!

    const siteOptions = useMemo(() => {
        // Extract all site names from the data and remove duplicates
        const allSites = hotelsData.map((row) => row.site)
        return [...new Set(allSites)].sort()
    }, [hotelsData])
    // -----------------------
    //   FILTERING LOGIC
    // -----------------------
    const filteredData = useMemo(() => {
        return hotelsData.filter((row) => {
            // If siteFilter is empty, we match all sites.
            const matchesSite = siteFilter.length === 0 || siteFilter.includes(row.site)

            const matchesYear = !yearFilter || String(row.anneeLivraison) === String(yearFilter)

            return matchesSite && matchesYear
        })
    }, [hotelsData, siteFilter, yearFilter])

    // -----------------------
    //   SORTING LOGIC
    // -----------------------
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const sortedData = useMemo(() => {
        return stableSort(filteredData, getComparator(order, orderBy))
    }, [filteredData, order, orderBy])

    // -----------------------
    //   CHARTS CONFIG
    // -----------------------
    const siteNames = sortedData.map((d) => d.site)
    const consoElecValues = sortedData.map((d) => d.consoElecKwh)
    const consoGazValues = sortedData.map((d) => d.consoGazKwh)

    const chartOptionsElec = {
        chart: {
            type: 'column',
            height: 200,
            backgroundColor: 'transparent',
        },
        title: {
            text: '',
            style: { fontSize: '14px' },
        },
        xAxis: {
            categories: siteNames,
            crosshair: true,
            labels: {
                style: {
                    fontSize: '10px', // Adjust the font size here
                },
            },
        },
        yAxis: {
            min: 0,
            title: { text: 'kWh' },
        },
        legend: { enabled: false },
        series: [
            {
                name: 'Conso Elec',
                data: consoElecValues,
                color: '#1976d2',
            },
            {
                name: 'Conso Gaz',
                data: consoGazValues,
                color: '#f44336',
            },
        ],
    }
    const totalElec = sortedData.reduce((sum, row) => sum + row.consoElecKwh, 0)
    const totalGaz = sortedData.reduce((sum, row) => sum + row.consoGazKwh, 0)
    const totalChauff = sortedData.reduce((sum, row) => sum + row.chauffageUrbainKwh, 0)
    const chartOptionsDoughnut = {
        chart: {
            type: 'pie',
            height: 200,
            backgroundColor: 'transparent',
        },
        title: {
            text: '',
            style: { fontSize: '14px' },
        },
        plotOptions: {
            pie: {
                innerSize: '50%', // This creates the “doughnut” hole
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y}',
                    style: {
                        fontSize: '10px',
                    },
                },
            },
        },
        series: [
            {
                name: 'kWh total',
                data: [
                    { name: 'Electricité', y: totalElec },
                    { name: 'Gaz', y: totalGaz },
                    { name: 'Chauffage Urbain', y: totalChauff },
                ],
            },
        ],
    }

    // -----------------------
    //   EXPORT (CSV/EXCEL)
    // -----------------------
    const csvHeaders = [
        { label: 'Site', key: 'site' },
        { label: 'Nb Chambres', key: 'nombreChambre' },
        { label: 'Année Livraison', key: 'anneeLivraison' },
        { label: 'Puissance Elec Souscrite', key: 'puissanceElecSouscrite' },
        { label: 'Puissance Elec Atteinte', key: 'puissanceElecAtteinte' },
        { label: 'Conso Elec (kWh)', key: 'consoElecKwh' },
        { label: 'Conso Gaz (kWh)', key: 'consoGazKwh' },
        { label: 'Chauffage Urbain (kWh)', key: 'chauffageUrbainKwh' },
        { label: 'Totaux (kWh)', key: 'totauxKwh' },
        { label: 'SU/parking (m²)', key: 'suSansLtEtParkingM2' },
        { label: 'Nuitées', key: 'nbreNuitee' },
        { label: 'Conso kWh / m²', key: 'consoKwhM2' },
        { label: 'Conso kWh / chambre', key: 'consoKwhChambre' },
        { label: 'Conso kWh / nuitée', key: 'consoKwhNuitee' },
        { label: 'Conso kWh / m³', key: 'consoKwhM3' },
        { label: 'Conso Gaz (m³)', key: 'consoGazM3' },
        { label: 'Eau (m³)', key: 'consoEauM3' },
        { label: 'Eau (m³/nuitée)', key: 'consoEauM3Nuitee' },
        { label: 'Eau (L/nuitée)', key: 'consoEauLNuitee' },
        { label: 'Particularités', key: 'particularites' },
        { label: 'Commentaires', key: 'commentaires' },
    ]

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(sortedData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'HotelsData')
        XLSX.writeFile(wb, 'hotels-data.xlsx')
    }

    const [selectedRow, setSelectedRow] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)

    const handleOpenDialog = (row) => {
        setSelectedRow(row)
        setDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setDialogOpen(false)
        setSelectedRow(null)
    }

    // This function will receive the updated row from the dialog
    const handleSaveDialog = (updatedSite) => {
        setHotelsData((prev) => prev.map((site) => (site.site === updatedSite.site ? updatedSite : site)))
        setDialogOpen(false)
        setSelectedRow(null)
    }

    // -----------------------
    //     RENDER
    // -----------------------
    return (
        <Box sx={{ p: 2 }}>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    mb: 2,
                    flexWrap: 'wrap',
                    alignItems: 'center',
                }}
            >
                <Autocomplete
                    multiple
                    options={siteOptions}
                    value={siteFilter}
                    onChange={(event, newValue) => setSiteFilter(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Sélectionner un ou plusieurs sites"
                            variant="outlined"
                            sx={{ minWidth: '200px', background: 'white' }}
                        />
                    )}
                    sx={{ flex: 1 }}
                />
                {/* <TextField
                    label="Rechercher un site"
                    variant="outlined"
                    value={siteFilter}
                    onChange={(e) => setSiteFilter(e.target.value)}
                    sx={{ minWidth: '200px', flex: 1, background: 'white' }}
                /> */}
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                    gap: 2,
                    mb: 3,
                }}
            >
                <Paper sx={{ p: 2 }} elevation={3}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptionsElec} />
                </Paper>

                <Paper sx={{ p: 2 }} elevation={3}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptionsDoughnut} />
                </Paper>
            </Box>
            {/* TABLE */}{' '}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    mb: 2, // give some spacing below
                }}
            >
                <Button variant="outlined">
                    <CSVLink
                        data={sortedData}
                        headers={csvHeaders}
                        filename="hotels-data.csv"
                        style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                        Export CSV
                    </CSVLink>
                </Button>
                <Button variant="contained" onClick={exportToExcel}>
                    Export Excel
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                        <TableRow>
                            {/* SITE */}
                            <TableCell sortDirection={orderBy === 'site' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'site'}
                                    direction={orderBy === 'site' ? order : 'asc'}
                                    onClick={() => handleRequestSort('site')}
                                >
                                    Site
                                </TableSortLabel>
                            </TableCell>

                            {/* NB CHAMBRES */}
                            <TableCell sortDirection={orderBy === 'nombreChambre' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'nombreChambre'}
                                    direction={orderBy === 'nombreChambre' ? order : 'asc'}
                                    onClick={() => handleRequestSort('nombreChambre')}
                                >
                                    Nb Chambres
                                </TableSortLabel>
                            </TableCell>

                            {/* ANNÉE */}
                            <TableCell sortDirection={orderBy === 'anneeLivraison' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'anneeLivraison'}
                                    direction={orderBy === 'anneeLivraison' ? order : 'asc'}
                                    onClick={() => handleRequestSort('anneeLivraison')}
                                >
                                    Année Liv.
                                </TableSortLabel>
                            </TableCell>

                            {/* PUISS. ELEC SOUSCRITE */}
                            <TableCell sortDirection={orderBy === 'puissanceElecSouscrite' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'puissanceElecSouscrite'}
                                    direction={orderBy === 'puissanceElecSouscrite' ? order : 'asc'}
                                    onClick={() => handleRequestSort('puissanceElecSouscrite')}
                                >
                                    Puiss. Elec Souscrite
                                </TableSortLabel>
                            </TableCell>

                            {/* PUISS. ELEC ATTEINTE */}
                            <TableCell sortDirection={orderBy === 'puissanceElecAtteinte' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'puissanceElecAtteinte'}
                                    direction={orderBy === 'puissanceElecAtteinte' ? order : 'asc'}
                                    onClick={() => handleRequestSort('puissanceElecAtteinte')}
                                >
                                    Puiss. Elec Atteinte
                                </TableSortLabel>
                            </TableCell>

                            {/* CONSO ÉLEC */}
                            <TableCell sortDirection={orderBy === 'consoElecKwh' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoElecKwh'}
                                    direction={orderBy === 'consoElecKwh' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoElecKwh')}
                                >
                                    Conso Elec (kWh)
                                </TableSortLabel>
                            </TableCell>

                            {/* CONSO GAZ */}
                            <TableCell sortDirection={orderBy === 'consoGazKwh' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoGazKwh'}
                                    direction={orderBy === 'consoGazKwh' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoGazKwh')}
                                >
                                    Conso Gaz (kWh)
                                </TableSortLabel>
                            </TableCell>

                            {/* CHAUFFAGE URBAIN */}
                            <TableCell sortDirection={orderBy === 'chauffageUrbainKwh' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'chauffageUrbainKwh'}
                                    direction={orderBy === 'chauffageUrbainKwh' ? order : 'asc'}
                                    onClick={() => handleRequestSort('chauffageUrbainKwh')}
                                >
                                    Chauff. Urbain (kWh)
                                </TableSortLabel>
                            </TableCell>

                            {/* TOTAUX */}
                            <TableCell sortDirection={orderBy === 'totauxKwh' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'totauxKwh'}
                                    direction={orderBy === 'totauxKwh' ? order : 'asc'}
                                    onClick={() => handleRequestSort('totauxKwh')}
                                >
                                    Totaux (kWh)
                                </TableSortLabel>
                            </TableCell>

                            {/* SU/PARKING */}
                            <TableCell sortDirection={orderBy === 'suSansLtEtParkingM2' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'suSansLtEtParkingM2'}
                                    direction={orderBy === 'suSansLtEtParkingM2' ? order : 'asc'}
                                    onClick={() => handleRequestSort('suSansLtEtParkingM2')}
                                >
                                    SU/parking (m²)
                                </TableSortLabel>
                            </TableCell>

                            {/* NUITÉES */}
                            <TableCell sortDirection={orderBy === 'nbreNuitee' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'nbreNuitee'}
                                    direction={orderBy === 'nbreNuitee' ? order : 'asc'}
                                    onClick={() => handleRequestSort('nbreNuitee')}
                                >
                                    Nuitées
                                </TableSortLabel>
                            </TableCell>

                            {/* CONSO KWH / M² */}
                            <TableCell sortDirection={orderBy === 'consoKwhM2' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoKwhM2'}
                                    direction={orderBy === 'consoKwhM2' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoKwhM2')}
                                >
                                    Conso kWh / m²
                                </TableSortLabel>
                            </TableCell>

                            {/* CONSO KWH / CHAMBRE */}
                            <TableCell sortDirection={orderBy === 'consoKwhChambre' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoKwhChambre'}
                                    direction={orderBy === 'consoKwhChambre' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoKwhChambre')}
                                >
                                    Conso kWh / chambre
                                </TableSortLabel>
                            </TableCell>

                            {/* CONSO KWH / NUITÉE */}
                            <TableCell sortDirection={orderBy === 'consoKwhNuitee' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoKwhNuitee'}
                                    direction={orderBy === 'consoKwhNuitee' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoKwhNuitee')}
                                >
                                    Conso kWh / nuitée
                                </TableSortLabel>
                            </TableCell>

                            {/* CONSO KWH / M³ */}
                            <TableCell sortDirection={orderBy === 'consoKwhM3' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoKwhM3'}
                                    direction={orderBy === 'consoKwhM3' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoKwhM3')}
                                >
                                    Conso kWh / m³
                                </TableSortLabel>
                            </TableCell>

                            {/* CONSO GAZ (M³) */}
                            <TableCell sortDirection={orderBy === 'consoGazM3' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoGazM3'}
                                    direction={orderBy === 'consoGazM3' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoGazM3')}
                                >
                                    Conso Gaz (m³)
                                </TableSortLabel>
                            </TableCell>

                            {/* EAU (M³) */}
                            <TableCell sortDirection={orderBy === 'consoEauM3' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoEauM3'}
                                    direction={orderBy === 'consoEauM3' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoEauM3')}
                                >
                                    Eau (m³)
                                </TableSortLabel>
                            </TableCell>

                            {/* EAU (M³/NUITÉE) */}
                            <TableCell sortDirection={orderBy === 'consoEauM3Nuitee' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoEauM3Nuitee'}
                                    direction={orderBy === 'consoEauM3Nuitee' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoEauM3Nuitee')}
                                >
                                    Eau (m³/nuitée)
                                </TableSortLabel>
                            </TableCell>

                            {/* EAU (L/NUITÉE) */}
                            <TableCell sortDirection={orderBy === 'consoEauLNuitee' ? order : false}>
                                <TableSortLabel
                                    active={orderBy === 'consoEauLNuitee'}
                                    direction={orderBy === 'consoEauLNuitee' ? order : 'asc'}
                                    onClick={() => handleRequestSort('consoEauLNuitee')}
                                >
                                    Eau (L/nuitée)
                                </TableSortLabel>
                            </TableCell>

                            {/* PARTICULARITÉS */}
                            <TableCell>Particularités</TableCell>
                            {/* COMMENTAIRES */}
                            <TableCell>Commentaires</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedData.map((hotel, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1, // small space between text and icon
                                        }}
                                    >
                                        <IconButton onClick={() => handleOpenDialog(hotel)} aria-label="edit">
                                            <EditIcon />
                                        </IconButton>
                                        {hotel.site}
                                    </Box>
                                </TableCell>
                                <TableCell>{hotel.nombreChambre}</TableCell>
                                <TableCell>{hotel.anneeLivraison}</TableCell>
                                <TableCell>{hotel.puissanceElecSouscrite}</TableCell>
                                <TableCell>{hotel.puissanceElecAtteinte}</TableCell>

                                <HighlightedTableCell value={hotel.consoElecKwh}>
                                    {hotel.consoElecKwh.toLocaleString()}
                                </HighlightedTableCell>
                                <HighlightedTableCell value={hotel.consoGazKwh}>
                                    {hotel.consoGazKwh.toLocaleString()}
                                </HighlightedTableCell>
                                <HighlightedTableCell value={hotel.chauffageUrbainKwh}>
                                    {hotel.chauffageUrbainKwh.toLocaleString()}
                                </HighlightedTableCell>
                                <HighlightedTableCell value={hotel.totauxKwh}>
                                    {hotel.totauxKwh.toLocaleString()}
                                </HighlightedTableCell>
                                <TableCell>{hotel.suSansLtEtParkingM2.toLocaleString()}</TableCell>
                                <TableCell>{hotel.nbreNuitee.toLocaleString()}</TableCell>
                                <TableCell>{hotel.consoKwhM2}</TableCell>
                                <TableCell>{hotel.consoKwhChambre}</TableCell>
                                <TableCell>{hotel.consoKwhNuitee}</TableCell>
                                <TableCell>{hotel.consoKwhM3}</TableCell>
                                <TableCell>{hotel.consoGazM3}</TableCell>
                                <TableCell>{hotel.consoEauM3}</TableCell>
                                <TableCell>{hotel.consoEauM3Nuitee}</TableCell>
                                <TableCell>{hotel.consoEauLNuitee}</TableCell>
                                <TableCell>
                                    {hotel.particularites?.map((tag, idx) => (
                                        <Chip
                                            key={idx}
                                            label={tag}
                                            variant="outlined"
                                            color={
                                                tag.toLowerCase().includes('spa')
                                                    ? 'secondary'
                                                    : tag.toLowerCase().includes('resto')
                                                      ? 'primary'
                                                      : 'default'
                                            }
                                            size="small"
                                            sx={{ mr: 0.5, mb: 0.5 }}
                                        />
                                    ))}
                                </TableCell>
                                <TableCell>{hotel.commentaires}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                    Observations : Certaines valeurs sont données à titre indicatif et les surfaces utiles ne
                    comprennent pas les locaux techniques, annexes ou parking. Ces surfaces ne rentrant pas dans le
                    bilan carbone.
                </Typography>
            </Box>
            <EditDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                siteData={selectedRow}
                onSave={handleSaveDialog}
            />
        </Box>
    )
}
