export const errors_codes = {
    277: {
        '---': 'Communication Error',
        A0: 'External protection device activated',
        A1: 'Malfunction of indoor unit PCB',
        A3: 'Drain Level Control System Abnormality',
        A4: 'Malfunction of freezing protection',
        A5: 'High pressure control in heating, freeze-up protection control in cooling',
        A6: 'Malfunction of fan motor',
        A7: 'Malfunction of swing flap motor',
        A8: 'Malfunction of power supply or AC input overcurrent',
        A9: 'Malfunction of electronic expansion valve',
        AA: 'Heater overheat',
        AF: 'Malfunction of a humidifier system',
        AH: 'Malfunction of dust collector of air cleaner',
        AJ: 'Malfunction of capacity setting (Indoor unit PCB)',
        C1: 'Failure of transmission (between indoor unit PCB and sub PCB)',
        C4: 'Malfunction of liquid pipe thermistor for heat exchanger',
        C5: 'Malfunction of gas pipe thermistor for heat exchanger',
        C6: 'Malfunction of fan motor sensor or fan control driver',
        C7: 'Front panel driving motor fault',
        C9: 'Malfunction of suction air thermistor',
        CA: 'Malfunction of discharge air thermistor',
        CC: 'Malfunction of humidity sensor system',
        CJ: 'Room temperature thermistor in remote controller abnormality',
        E0: 'Protection devices activated (unified)',
        E1: 'Defective outdoor unit PCB',
        E3: 'Actuation of high pressure switch (HPS)',
        E4: 'Actuation of low pressure switch (LPS)',
        E5: 'Inverter compressor motor or overheat',
        E6: 'STD compressor motor overcurrent/lock',
        E7: 'Malfunction of outdoor unit fan motor system',
        E8: 'Overcurrent of inverter compressor',
        E9: 'Malfunction of electronic expansion valve coil',
        EA: 'Malfunction of four way valve or cool/heat switching',
        EC: 'Malfunction of entering water temperature',
        F3: 'Malfunction of discharge pipe temperature',
        F6: 'Abnormal high pressure or refrigerant overcharged',
        H0: 'Malfunction of sensor system of compressor',
        H1: 'Malfunction of room temperature sensor or humidifier unit damper',
        H3: 'Malfunction of high pressure switch (HPS)',
        H4: 'Malfunction of low pressure switch (LPS)',
        H5: 'Malfunction of compressor motor overload thermistor',
        H6: 'Malfunction of position detection sensor',
        H7: 'Malfunction of outdoor unit fan motor signal',
        H8: 'Malfunction of compressor input (CT) system',
        H9: 'Malfunction of outdoor air thermistor',
        HC: 'Malfunction of (hot) water temperature thermistor',
        HF: 'Alarm in thermal storage unit or storage controller',
        HJ: 'Malfunction of thermal storage tank water level',
        J1: 'Malfunction of pressure sensor',
        J2: 'Malfunction of current sensor of compressor',
        J3: 'Malfunction of discharge pipe thermistor',
        J4: 'Malfunction of low pressure equivalent saturated temperature sensor system',
        J5: 'Malfunction of suction pipe thermistor',
        J6: 'Malfunction of heat exchanger thermistor',
        J7: 'Malfunction of thermistor (Refrigerant circuit)',
        J8: 'Malfunction of thermistor (Refrigerant circuit)',
        J9: 'Malfunction of thermistor (Refrigerant circuit)',
        JA: 'Malfunction of high pressure sensor',
        JC: 'Malfunction of low pressure sensor',
        JE: 'Malfunction of oil pressure sensor or sub-tank thermistor',
        JF: 'Malfunction of oil level sensor or heating heat exchanger thermistor',
        L0: 'Malfunction of inverter system',
        L1: 'Malfunction of inverter PCB',
        L3: 'El.compo. box temperature rise',
        L4: 'Malfunction of inverter radiation fin temperature rise',
        L5: 'Inverter instantaneous overcurrent (DC output)',
        L6: 'Inverter instantaneous overcurrent (AC output)',
        L8: 'Malfunction of overcurrent inverter compressor',
        L9: 'Malfunction of inverter compressor startup error (Stall prevention)',
        LA: 'Malfunction of power transistor',
        LC: 'Malfunction of transmission between control and inverter PCB',
        M1: 'Malfunction of central remote controller PCB',
        M8: 'Malfunction of transmission between optional controllers for centralized control',
        MA: 'Improper combination of optional controllers for centralized control',
        MC: 'Address duplication, improper setting',
        P0: 'Shortage of refrigerant amount (thermal storage unit)',
        P1: 'Power voltage imbalance or inverter PCB',
        P2: 'Automatic refrigerant charge operation stop',
        P3: 'Malfunction of thermistor in switch box',
        P4: 'Malfunction of radiation fin temperature sensor',
        P8: 'Heat exchanger freezing protection during automatic refrigerant charging',
        P9: 'Automatic refrigerant charge operation completed',
        PA: 'Refrigerant cylinder during automatic refrigerant charging',
        PC: 'Refrigerant cylinder during automatic refrigerant charging',
        PE: 'Automatic refrigerant charge operation nearly completed',
        PH: 'Refrigerant cylinder during automatic refrigerant charging',
        PJ: 'Malfunction of capacity setting (Outdoor unit PCB)',
        S0: 'Shortage of refrigerant',
        U0: 'Shortage of refrigerant',
        U1: 'Reverse phase, open phase',
        U2: 'Malfunction of power supply or instantaneous power failure',
        U3: 'Check operation not executed or transmission error',
        U4: 'Malfunction of transmission between indoor and outdoor unit',
        U5: 'Malfunction of transmission between indoor unit and remote controller',
        U6: 'Malfunction of transmission between indoor units',
        U7: 'Malfunction of transmission between outdoor units or outdoor storage unit',
        U8: 'Malfunction of transmission between remote controllers',
        U9: 'Malfunction of transmission (other system)',
        UA: 'Defect of indoor/outdoor power supply',
        UC: 'Malfunction of setting of centralized control equipment address',
        UE: 'Malfunction of transmission between indoor unit and centralized control equipment',
        UF: 'System is not set yet',
        UH: 'Malfunction of system',
        UJ: 'Malfunction of transmission (accessory device)',
        '60': 'External protection device activated (Heat reclaim ventilator)',
        '64': 'Malfunction of indoor air thermistor (Heat reclaim ventilator)',
        '65': 'Malfunction of outdoor air thermistor (Heat reclaim ventilator)',
        '6A': 'Malfunction of damper system (Heat reclaim ventilator)',
        '70': 'System No. 2 Compressor overheat',
        '71': 'System No. 2 Compressor overcurrent',
        '72': 'System No. 2 Fan motor overcurrent',
        '73': 'System No. 2 Actuation of high pressure switch (HPS)',
        '74': 'System No. 2 Actuation of low pressure switch (LPS)',
        '75': 'System No. 2 Malfunction of low pressure sensor',
        '76': 'System No. 2 Malfunction of high pressure sensor',
        '77': 'System No. 1 Malfunction of fan inter lock',
        '78': 'System No. 2 Malfunction of fan inter lock',
        '7A': 'System No. 2 Malfunction of compressor current sensor',
        '7C': 'System No. 2 Malfunction of pump inter lock',
        '80': 'Malfunction of entering water temperature thermistor',
        '81': 'Malfunction of leaving water temperature thermistor or drain pipe heater',
        '82': 'System No. 1 Malfunction of refrigerant thermistor',
        '83': 'System No. 2 Malfunction of refrigerant thermistor',
        '84': 'System No. 1 Malfunction of heat exchanger thermistor',
        '85': 'System No. 2 Malfunction of heat exchanger thermistor',
        '86': 'System No. 1 Malfunction of discharge pipe thermistor',
        '88': 'System No. 2 Malfunction of discharge pipe temperature',
        '89': 'Malfunction of brazed-plate heat exchanger freezing',
        '8A': 'System No. 2 Malfunction of leaving water temperature thermistor',
        '8E': 'System No. 1 Malfunction of suction pipe thermistor 1 for heating',
        '8F': 'System No. 1 Malfunction of suction pipe thermistor 2 for heating',
        '8H': 'Abnormal hot water high temperature',
        '90': 'Abnormal chilled water quantity or abnormal AXP',
        '91': 'System No. 2 Malfunction of electronic expansion valve',
        '92': 'System No. 2 Malfunction of suction pipe thermistor',
        '94': 'Malfunction of transmission (between heat reclaim ventilator and fan unit)',
        '95': 'System No. 1 Malfunction of inverter system',
        '96': 'System No. 2 Malfunction of inverter system',
        '97': 'Malfunction of thermal storage unit',
        '98': 'Malfunction of thermal storage brine pump',
        '99': 'Malfunction of thermal storage brine tank',
    },
    275: {
        '0092': 'Version combination error',
        '0093': 'System configuration change warning',
        '0094': '"Charge" license not registered',
        '0095': 'Warning - possibility of damaged<br>metering device',
        '0097': 'Apportioned calculation data collection <br>error',
        '0100': 'Equipment abnormality',
        '0110': 'Equipment abnormality 1',
        '0120': 'Equipment abnormality 2',
        '0130': 'Equipment abnormality 3',
        '0140': 'Equipment abnormality 4',
        '0150': 'Equipment abnormality 5',
        '0160': 'Equipment abnormality 6',
        '01*0': 'Equipment abnormality (PAC-YG66DCA) in system *',
        '01**': 'Equipment abnormality in system **',
        '0206': 'Optional equipment error',
        '0403': 'Serial transmission trouble',
        '0404': 'Indoor unit EEPROM error (A)',
        '0701': 'Combustion circuit abnormality (A)',
        '0702': 'Combustion heat exchange overheating protection (A)',
        '0703': 'Accidental fire (A)',
        '0704': 'Heater abnormality (A)',
        '0705': 'Seismoscope malfunction (A)',
        '0706': 'Flame current sensor abnormality (A)',
        '0707': 'Ignition abnormality (A)',
        '0708': 'Blower motor rotation abnormality (A)',
        '0709': 'Oil pump circuit abnormality (A)',
        '0900': 'Test run',
        '1000': 'Ref.cycle abnormality',
        '10*0': 'Ref.cycle abnormality in line *',
        '1102': 'Discharge temperature abnormality (TH4) (A)',
        '1108': 'Inner thermo (49C) operation (A)',
        '11**': 'Ref.cycle temperature abnormality - Common operand: **',
        '1300': 'Low-pressure abnormality (63L operation) (A)',
        '13**': 'Ref.cycle pressure abnormality - Common operand: **',
        '1500': 'Ref.cycle not operate due to overcharge',
        '1501': 'Ref.cycle not operate due to undercharge<br>(/compressor shell temperature abnormality)',
        '1502': 'Ref.cycle not operate due to<br>liquid back /Low-discharge super heat abnormality (A)',
        '1503': 'Ref.cycle not operate due to coil frost',
        '1504': 'Ref.cycle not operate due to overheat protection',
        '1505': 'Ref.cycle not operate due to<br>compressor vacuum operation protection/<br>refrigerant low temperature abnormality',
        '1506': 'Ref.cycle not operate due to refrigerant pump abnormality',
        '1507': 'Ref.cycle not operate due to composition detection abnormality',
        '1508': 'Ref.cycle not operate due to control valve fault',
        '1509': 'Ref.cycle not operate due to high pressure abnormality (ball valve closed) ',
        '1510': 'Ref.cycle - Gas leakage',
        '1511': 'Ref.cycle not operate due to oil slick abnormality',
        '1512': 'Ref.cycle not operate due to a stop of freezing protection function',
        '1513': 'Ref.cycle - Brine freezing',
        '1559': 'Oil balance circuit abnormality',
        '2000': 'Water system abnormality (Pump interlock abnormality)',
        '20*0': 'Water system abnormality in line *',
        '21**': 'Water system temperature abnormality - Common operand: **',
        '23**': 'Water system pressure abnormality - Common operand: **',
        '2500': 'Water system not operate due to water leak',
        '2501': 'Water system not operate due to water supply suspension',
        '2502': 'Water system not operate due to drain pump abnormality',
        '2503': 'Water system not operate due to drain sensor abnormality/float switch function/flow switch function',
        '2504': 'Water system not operate due to liquid level abnormality',
        '2505': 'Water system not operate due to cool water valve abnormality',
        '2506': 'Water system not operate due to warm water valve abnormality',
        '2507': 'Water system not operate due to dew condensation prevention control activated',
        '2600': 'Water system operation restricted due to water leak',
        '2601': 'Water system operation restricted due to<br>water supply suspension/humidifier water supply suspension',
        '2602': 'Water system operation restricted due to drain pump abnormality',
        '2603': 'Water system operation restricted due to drain sensor abnormality',
        '2604': 'Water system operation restricted due to liquid level abnormality',
        '2613': 'Drop in water flow rate',
        '3152': 'Air system operation restricted due to<br>inverter control box inner temperature abnormality ',
        '3182': 'Air system operation restricted due to housing inner temperature abnormality',
        '3600': 'Air system operation restricted due to filter clogging',
        '3601': 'Air system operation restricted due to filter maintenance',
        '3602': 'Air system operation restricted due to damper position detecting abnormality',
        '37**': 'Air system operation humidity abnormality allowance - Common operand: **',
        '38**': 'Air system operation humidity abnormality - Common operand: **',
        '4000': 'Electric system abnormality',
        '40*0': 'Electric system abnormality in line *',
        '4100': 'Electric system not operate due to overcurrent shut-off',
        '4101': 'Electric system not operate due to overcurrent protection',
        '4102': 'Electric system not operate due to open phase /Open phase (T phase) (A)',
        '4103': 'Electric system not operate due to reversed phase/open phase',
        '4104': 'Electric system not operate due to electric leak',
        '4105': 'Electric system not operate due to short circuit',
        '4106': 'Electric system not operate due to self power supply OFF/power failure',
        '4107': 'Electric system not operate due to overload',
        '4108': 'Electric system not operate due to<br>overload protection/OCR51C /Open phase (S phase), 51CM connector open (A)',
        '4109': 'Electric system not operate due to OCR51F',
        '4110': 'Electric system not operate due to high voltage part',
        '4111': 'Electric system not operate due to bus current',
        '4112': 'Electric system not operate due to coil overheat 49°C',
        '4113': 'Electric system not operate due to heater overheat',
        '4114': 'Electric system not operate due to fan controller abnormality',
        '4115': 'Electric system not operate due to<br>power supply synchronism abnormality /Input circuit (board) failure',
        '4116': 'Electric system not operate due to motor abnormality/speed abnormality',
        '4117': 'Compressor self-protection function operation (A)',
        '4118': 'Opposite phase detection circuit (board) failure (A)',
        '4119': 'Open of 2 or more connectors (A)',
        '4121': 'Electric system not operate due to<br>trouble in equipment to which a measure against higher harmonics is taken',
        '4123': 'Electric system not operate due to Inverter output error ',
        '4124': 'Electric system not operate due to damper abnormality ',
        '4125': 'Electric system - Rush-proof circuit abnormality',
        '4200': 'Inverter abnormality',
        '420*': 'Inverter abnormality - Inverter No.: *',
        '4210': 'Inverter overcurrent shut-off',
        '421*': 'Inverter overcurrent shut-off - Inverter No.: *',
        '4220': 'Inverter bus voltage insufficiency / Voltage abnormality (A)',
        '422*': 'Inverter bus voltage insufficiency - Inverter No.: *',
        '4230': 'Inverter radiating thermostat abnormality',
        '423*': 'Inverter radiating thermostat abnormality - Inverter No.: *',
        '4240': 'Inverter overcurrent (overload) protection',
        '424*': 'Inverter overcurrent protection - Inverter No.: *',
        '4250': 'Inverter IPM/bus voltage abnormality /Power module abnormality (A)',
        '425*': 'Inverter IPM abnormality *',
        '4260': 'Inverter cooling fan trouble',
        '426*': 'Inverter cooling fan trouble - Inverter No.: *',
        '5000': 'Sensor trouble',
        '50*0': 'Sensor trouble in system *',
        '51**': 'Temperature sensor trouble - Sensor No.: **',
        '5202': 'Connector (63L) open (A)/Low-pressure sensor fault/Low-pressure fault',
        '52**': 'Pressure sensor trouble - Sensor No.: **',
        '5300': 'Current sensor abnormality (A)',
        '53**': 'Current sensor trouble - Sensor No.: **',
        '54**': 'Humidity sensor trouble - Sensor No.: **',
        '55**': 'Gas sensor trouble - Sensor No.: **',
        '56**': 'Air speed sensor trouble - Sensor No.: **',
        '57**': 'Limit switch trouble - Switch No.: **',
        '58**': 'Sensor trouble - Sensor No.: **',
        '59**': 'Other sensors trouble - Sensor No.: **',
        '6000': 'System abnormality',
        '6101': 'System not operate due to abnormality - With response frame',
        '6102': 'No answer back',
        '6104': 'System abnormality - PLC detected an error.',
        '6105': 'System abnormality - PLC detected an unmatch.',
        '6200': 'Controller H/W abnormality',
        '6201': 'E2PROM abnormality',
        '6202': 'RTC abnormality',
        '6204': 'External memory read/write error',
        '6205': 'External memory cannot be written to any more.',
        '6500': 'Communication error',
        '6600': 'Communication error - Address duplicate',
        '6601': 'Communication error - Polarity unsettled',
        '6602': 'Communication error - Transmission processor hardware error',
        '6603': 'Communication error - Transmission line busy',
        '6604': 'Communication error - No ACK (06H) (communication circuit error)',
        '6605': 'Communication error - No response frame',
        '6606': 'Communication error - Transmission processor communication error',
        '6607': 'Communication error - No ACK return',
        '6608': 'Communication error - No return of response frame',
        '6609': 'Communication error',
        '6610': 'Communication error',
        '6800': 'Communication error - Other communication errors',
        '6801': 'Communication error - V-control communication error',
        '6810': 'Communication error - UR communication error',
        '6811': 'Communication error - UR communication synchronism not recover',
        '6812': 'Communication error - UR communication hardware error',
        '6813': 'Communication error - UR communication status bit detection error',
        '6820': 'Other communication errors',
        '6821': 'Other communication errors - Transmission line busy',
        '6822': 'Other communication errors - No communication ACK',
        '6823': 'Other communication errors - No response command',
        '6824': 'Other communication errors - Receive data error',
        '6830': 'Communication error - <br>MA communication refrigerant address double setting error',
        '6831': 'Communication error - No MA communication reception error',
        '6832': 'Communication error - MA communication synchronism not recover',
        '6833': 'Communication error -<br>MA communication transmission/reception hardware trouble',
        '6834': 'Communication error - MA communication start bit detection error',
        '6840': 'Communication error -<br>A control no indoor/outdoor communication/reception abnormality',
        '6841': 'Communication error -<br>A control indoor/outdoor communication synchronization recovery abnormal',
        '6844': 'Communication error -<br>A control indoor/outdoor communication<br>incorrect indoor/outdoor wiring connection,<br>excessive number of indoor units (more than five units)',
        '6845': 'Communication error -<br>A control indoor/outdoor communication incorrect indoor/outdoor wiring connection<br>(telecommunication, disconnection)',
        '6846': 'Communication error -<br>A control indoor/outdoor communication startup time exceeded',
        '6920': 'Communication error - No response',
        '6922': 'Communication error - Receiving frame ID<br>error ',
        '7000': 'System abnormality',
        '7100': 'System abnormality - Total capacity error',
        '7101': 'System abnormality - Capacity code error',
        '7102': 'System abnormality - Connecting unit number excess',
        '7103': 'System abnormality - Piping length setting error',
        '7104': 'System abnormality - Floor height setting error',
        '7105': 'System abnormality - Address setting over 254',
        '7106': 'System abnormality - Attribute setting error',
        '7107': 'System abnormality - Distributor setting error',
        '7108': 'System abnormality - Ref. system setting error',
        '7109': 'System abnormality - Connection setting error',
        '7110': 'System abnormality - Ref. system connection/connection data unsettled',
        '7111': 'System abnormality -<br>I/O connection equipment not connected/remote controller sensor abnormality',
        '7112': 'System abnormality - I/O type setting error',
        '7113': 'System abnormality - Equipment unsettled',
        '7116': 'System abnormality - Replace non-wash setting error',
        '7117': 'System abnormality - Model identification setting error',
        '7130': 'System abnormality - Different unit model error',
        '7131': 'System abnormality - Mixed cooling only H/P connection error (Facility PAC)',
        '7132': 'System abnormality - Multiple entries of operation performance (Facility PAC)',
        '7200': 'System abnormality - Numeric values unsettled',
        '7201': 'System abnormality - Numeric values unsettled',
        '73**': 'System abnormality - LON system equipment abnormality',
        '7901': 'System abnormality - Excessive number of connected units',
        '7902': 'System abnormality - Connection lock error',
        '7903': 'System abnormality - Unit information error',
        '7904': 'System abnormality - System setting error',
        '7905': 'System abnormality - Version error',
        '0310': 'Maintenance',
        '0311': 'Compressor long-hour operation',
        '1514': 'Ref.cycle - Refrigerant circuit abnormality',
        '1610': 'Ref.cycle - Gas leakage warning',
        '1615': 'Ref.cycle - Compressor frequent start-stops',
        '2508': 'Water system - Air pump abnormality',
        '2509': 'Water system - Brine pump abnormality',
        '2510': 'Water system - Water level abnormality (e.g., thermal storage tank)',
        '2511': 'Water system - Brine low level abnormality',
        '2512': 'Water system - Control valve fault',
        '2515': 'Water system - Water pump abnormality',
        '2608': 'Water system - Air pump abnormality',
        '2609': 'Water system - Brine pump abnormality',
        '2610': 'Water shortage error',
        '2611': 'Water system - Brine low level abnormality',
        '2613': 'Water system - Drop in water flow rate<br>',
        '2615': 'Water system - Water pump abnormality',
        '3604': 'Air system - Filter cleaning location detection abnormality (Mr. SLIM)',
        '3605': 'Air system - Filter cleaning dust box attachment abnormality (Mr. SLIM)',
        '3606': 'Air system - Filter cleaning location detection abnormality',
        '3607': 'Air system - Filter cleaning dust box attachment abnormality',
        '3608': 'Air system - Connection error of filter cleaning location detection connector',
        '3609': 'Air system - High ambient temperature',
        '3610': 'Air system - Heat exchanger clogging',
        '4126': 'Air system - Analog input abnormality',
        '4127': 'Air system - Deodorization circuit abnormality',
        '4128': 'Air system - Pulse discharge circuit abnormality',
        '5901': 'Potentiometer abnormality',
        '5902': 'Flow sensor abnormality',
        '0303': 'Pump B circuit error during hot water<br>storage operation/Pump circuit error<br>during heat-retaining operation',
        '2238': 'High outlet hot water temperature error',
        '1104': 'Low evaporation temperature fault',
        '1105': 'Heat exchanger outlet temperature fault',
        '1301': 'Vacuum protection fault',
        '1302': 'High pressure fault',
        '2518': 'Secondary side hot water temperature reduction error',
        '2616': 'Secondary side heat exchanger error',
    },
    278: {
        '11': 'Serial Communication Error between Indoor/ Outdoor Units',
        '12': 'Remote Controller Communication Error',
        '18': 'External Communication Error',
        '22': 'Indoor Unit Capacity Abnormal',
        '26': 'Indoor Unit Address Setting Error',
        '29': 'Connection Unit Number Error in Wired Remote Controller System',
        '32': 'Indoor Unit Main PCB Error',
        '35': 'Indoor Unit Manual Auto Switch Error',
        '3A': 'Indoor Unit Communication Circuit (Wired Remote Controller) Error',
        '41': 'Indoor Unit Room Temperature Thermistor Error',
        '42': 'Indoor Unit Heat Exchange Temperature Thermistor Error',
        '51': 'Indoor Unit Fan Motor 1 Error',
        '53': 'Indoor Unit Water Drain Abnormal',
        '57': 'Indoor Unit Damper Error',
        '58': 'Indoor Unit Intake Grille Position Error',
        '62': 'Outdoor Unit Main PCB Error',
        '64': 'Outdoor Unit Active Filter/ PFC Circuit Error',
        '65': 'Outdoor Unit IPM Error',
        '71': 'Outdoor Unit Discharge Temperature Thermistor Error',
        '72': 'Outdoor Unit Compressor Temperature Thermistor Error',
        '73': 'Outdoor Unit Heat Exchange Temperature Thermistor Error',
        '74': 'Outside Air Temperature Thermistor Error',
        '76': 'Outside Unit Operating Valve Thermistor Error',
        '77': 'Outdoor Unit Heat Sink Temperature Thermistor Error',
        '86': 'Outdoor Unit Pressure Sensor Error',
        '94': 'Outdoor Unit Trip Detection',
        '95': 'Outdoor Unit Compressor Motor Control Error',
        '97': 'Outdoor Unit Fan Motor 1 Error',
        '99': 'Outdoor Unit 4-Way Valve Error',
        A1: 'Outdoor Unit Discharge Temperature 1 Error',
        A3: 'Outdoor Unit Compressor Temperature Error',
    },
    286: {
        '01': 'Activation of Protection Device (Float Switch)',
        '02': 'Activation of Protection Device (High Pressure Cut)',
        '03': 'Abnormality between Indoor and Outdoor',
        '04': 'Abnormality between Inverter PCB and Outdoor PCB',
        '05': 'Supply Phase',
        '06': 'Abnormal Inverter Voltage',
        '07': 'Decrease in Discharge Gas Superheat',
        '08': 'Increase in Discharge Gas Temperature',
        '0A': 'Abnormality between Outdoor and Outdoor',
        '0b': 'Incorrect Outdoor Unit Address Setting',
        '0C': 'Incorrect Outdoor Unit Main Unit Setting',
        '11': 'Inlet Air Thermistor',
        '12': 'Outlet Air Thermistor',
        '13': 'Freeze Protection Thermistor',
        '14': 'Gas Piping Thermistor',
        '19': 'Activation of Protection Device for Indoor Fan',
        '21': 'High Pressure Sensor',
        '22': 'Outdoor Air Thermistor',
        '23': 'Discharge Gas Thermistor on Top of Compressor',
        '24': 'Heat Exchanger Liquid Pipe Thermistor',
        '25': 'Heat Exchanger Gas Pipe Thermistor',
        '29': 'Low Pressure Sensor',
        '31': 'Incorrect Capacity Setting of Outdoor Unit/Indoor Unit',
        '35': 'Incorrect Setting of Indoor Unit No.',
        '36': 'Incorrect of Indoor Unit Combination',
        '38': 'Abnormality of Picking up Circuit for Protection in Outdoor Unit',
        '39': 'Abnormality Running Current at Constant Speed Compressor',
        '3A': 'Abnormality of Outdoor Unit Capacity',
        '3b': 'Incorrect Setting of Outdoor Unit Models Combination or Voltage',
        '3d': 'Abnormality Transmission between Main Unit and Sub Unit(s)',
        '43': 'Activation of Low Compression Ratio Protection Device',
        '44': 'Activation of Low Pressure Increase Protection Device',
        '45': 'Activation of High Pressure Increase Protection Device',
        '47': 'Activation of Low Pressure Decrease Protection Device (Vacuum Operation Protection)',
        '48': 'Activation of Inverter Overcurrent Protection Device',
        '51': 'Abnormal Inverter Current Sensor',
        '53': 'Inverter Error Signal Detection',
        '54': 'Abnormality of Inverter Fin Temperature',
        '55': 'Inverter Failure',
        '57': 'Activation of Fan Controller Protection',
        '5A': 'Abnormality of Fan Controller Fin Temperature',
        '5B': 'Activation of Overcurrent Protection',
        '5C': 'Abnormality of Fan Controller Sensor',
        EE: 'Compressor Protection Alarm',
        b1: 'Incorrect Setting of Unit and Refrigerant Cycle No.',
        b5: 'Incorrect Indoor Unit Connection Number Setting',
    },
    288: {
        H11: 'No communication between indoor and outdoor unit',
        H12: 'Mismatch of the total power of indoor units to outdoor unit',
        H14: 'Air sensor shorted or disconnected',
        H15: 'Open circuit or short circuit in compressor temperature sensor',
        H16: 'Low current consumption by the external unit',
        H30: 'Outdoor discharge air temperature sensor abnormality',
        H33: 'Indoor / Outdoor wrong connection',
        H34: 'Outdoor heat sink temperature sensor abnormality',
        H36: 'Outdoor unit gas tube temperature sensor closed/torn off',
        H37: 'Outdoor unit liquid tube temperature sensor short-circuited',
        H38: 'Mismatch between outdoor and outdoor units',
        H39: 'Abnormal indoor operating unit or standby units',
        H41: 'Incorrect wiring and freon wiring',
        H50: 'Defective fan motor or board',
        H51: 'Nozzle blockage (AC Robot)',
        H52: 'Malfunction of the limiter switch (AC Robot)',
        H58: 'Malfunction of the Patrol Sensor unit',
        H64: 'Malfunction of the high pressure sensor',
        H97: 'Faulty compressor motor, indoor unit board',
        H98: 'Protection of the indoor unit from overheating in heat mode',
        H99: 'Freezing of the evaporator',
        F11: 'Incorrect operation of the four-way valve',
        F17: 'Freezing of the indoor unit in standby mode',
        F90: 'Open circuit of compressor windings',
        F91: 'Incorrect operation of the refrigeration circuit',
        F93: 'Open compressor windings, inverter board malfunction',
        F94: 'Overpressure protection',
        F95: 'Outdoor unit heat exchanger overheating',
        F96: 'Overheating of the power module',
        F97: 'High compressor discharge temperature',
        F98: 'Protection for total current consumption',
        F99: 'Error of the DC protection system',
    },
}
