type Sortable = {
    name: string
}

/**
 * Comparison method to use in Array.sort() to sort objects by name
 * @param a
 * @param b
 * @returns
 */
export const compareObjectsName = (a: Sortable, b: Sortable): number => {
    const aDigits = a?.name?.match(/\d+/g)?.[0]
    const bDigits = b?.name?.match(/\d+/g)?.[0]
    if (aDigits && bDigits) {
        const aPrefix = a.name.split(aDigits)[0]
        const bPrefix = b.name.split(bDigits)[0]
        if (aDigits === bDigits) {
            const aName = a?.name?.replace(/\d+/g, '')
            const bName = b?.name?.replace(/\d+/g, '')
            return aName.localeCompare(bName)
        }
        if (aPrefix === bPrefix) {
            return Number(aDigits) - Number(bDigits)
        }
    }
    return a?.name?.localeCompare(b.name)
}
